@keyframes shake {
  0% {
    transform: translateX(-1px);
  }
  33% {
    transform: translateX(2px);
  }
  67% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(1px);
  }
}

.MuiFormControl-root:has(> .Mui-error) {
  animation-name: shake;
  animation-duration: .15s;
  transform-origin: 50% 50%;
}
