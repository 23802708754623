@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:regular,bold,italic,light,extralight&subset=latin,latin-ext');

body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100vw;
  background-color: #e6e6e6;
}

:focus {
  border: none;
  outline: none;
}

html {
  background-color: #e6e6e6;
}

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
}

#root {
  position: relative;
  overflow: hidden;
}

body,
p,
span,
tspan {
  font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -ms-overflow-style: none;
}

/* clears the ‘X’ from Chrome/Safari */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}